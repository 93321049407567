
import Toolbar from '../components/Toolbar.vue';
import CookieBanner from '../components/cookies/CookieBanner.vue';
import NotLoginDialog from '~/components/enroll_without_account/NotLoginDialog.vue';

const toolbarVisibleArray = [
  'Login',
  'Register',
  'Register2',
  'Profile',
  'DashboardLessonDetail',
  'DashboardLessonDetailRate',
  'Home',
  'Profiles',
  'Password',
  'RegisterSuccess',
  'Enroll',
  'Enroll1',
  'Enroll2',
  'EnrollSuccess',
  'IndividualEnrollSuccess',
  'EnrollWithoutAccount',
  'EnrollWithoutAccountWithService',
  'EnrollCompleteData',
  'EnrollOTP',
  'HomeTeacher',
  'PaymentForm',
  'RegisterTeacher',
  'AccountNewPassword',
  'AccountRecovery',
  'InsertPin',
  'EditPin',
  'DeletePin',
  'FirstLogin',
  'BasicForm',
  'EducationForm',
  'ExperienceForm',
  'CertificatesForm',
  'LessonsAvailability',
  'LessonsForm',
  'AvailabilityForm',
  'Media',
  'Photo',
  'Video',
  'VideoRecord',
  'Summary',
  'SummaryResult',
  'EnrollVerifyProfileOtp',
  'EnrollPhoneNumberExist',
  'Notifications',
  'EnrollContinues',
  'EnrollContinuesSuccess',
  'PaymentServiceGroup',
  'DashboardQuizStart',
  'ServiceLeadOffer',
  'LeadOffer',
  'LeadOfferNew',
  'MaturaOffer',
  'PaymentPage',
  'Onboarding',
  'AskAboutServiceForm',
  'ProfilesTestRenewal',
  'IndividualTrial',
  'IndividualProceed',
  'IndividualBuy',
  'SchoolsMap',
  'WelcomeScreenTeacher',
  'RegisterTutoreTeacher',
  'RegisterTeacherResult',
  'DashboardLessonMaterial3',
  'DashboardLessonMaterial3New',
  'IndividualPaymentFinished',
  'EnrollAfterTrial',
  'PaymentIndividualNew',
  'BuyPassiveService',
  'BuyPassiveServiceFinished',
];

export default {
  name: 'App',
  components: {
    Toolbar,
    CookieBanner,
    NotLoginDialog,
  },

  data: () => ({
    showMessageBlock: true,
    tutorekWasLaunched: false,
  }),
  computed: {
    showTeacherDayBaner() {
      return this.$store.state.common.showTeacherDayBaner;
    },
    isTutor() {
      return this.$store.state.account.isTutor;
    },
    isTutorCompleteRegistration() {
      return this.$store.state.account.isTutorCompleteRegistration;
    },
    account() {
      return this.$store.state.account.account;
    },
    showNotLoginDialog() {
      return this.$store.state.common.showNotLoginDialog;
    },
    showEnrollTimer() {
      return this.$store.state.common.showEnrollTimer;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    toolbarVisible() {
      return !toolbarVisibleArray.includes(
        this.$route.name?.replace('___pl', '').replace('___en', '') ?? '',
      );
    },
    checkConsetsCookie() {
      const consentsCokokie = localStorage.getItem('consents');

      if (consentsCokokie) {
        return JSON.parse(consentsCokokie)?.Marketing_1;
      }

      return false;
    },
  },
  watch: {
    $route(rout) {
      this.checkAccess();
      const sendLead = this.$route.fullPath.includes('status=success');
      if (process.client) {
        this.goTop();
      }
      if (sendLead) {
        fbq('track', 'Lead');
      }
    },
    account() {
      if (this.account) {
        this.$store.dispatch('common/setReUseLessons');
      } else {
        this.$store.commit('common/setShowTeacherDayBaner', false);
      }
    },
  },
  async mounted() {
    const dontShowTutorekViews = [
      'ServiceLeadOffer',
      'LeadOffer',
      'Onboarding',
    ];
    const routeName = this.$route.name || '';

    if (process.client /*  && this.$config.environment !== 'production' */) {
      if (!dontShowTutorekViews.includes(routeName)) {
        window.addEventListener('scroll', this.handleScroll);
      }
    }
  },
  created() {
    if (process.client) {
      try {
        const profiles = localStorage.getItem('profile/profiles');
        if (profiles) {
          this.$store.commit('profile/setProfiles', JSON.parse(profiles));
        }

        const isTutor = localStorage.getItem('account/isTutor');
        if (isTutor) {
          this.$store.commit('account/setIsTutor', JSON.parse(isTutor));
        }

        const isTutorCompleteRegistration = localStorage.getItem(
          'account/isTutorCompleteRegistration',
        );
        if (isTutorCompleteRegistration) {
          this.$store.commit(
            'account/setIsTutorCompleteRegistration',
            JSON.parse(isTutorCompleteRegistration),
          );
        }

        const profileLocalStorage = localStorage.getItem('profile');
        if (profileLocalStorage) {
          this.$store.commit(
            'profile/setProfile',
            JSON.parse(profileLocalStorage),
          );
        } else if (profiles) {
          const profilesParsed = JSON.parse(profiles);
          const profilesWithoutPin = profilesParsed.filter(
            (profile) => !profile.isPinProtected,
          );
          if (profilesWithoutPin.length) {
            const tutorOrClientProfile = profilesWithoutPin.filter(
              (profile) =>
                profile.role === 'client' || profile.role === 'tutor',
            );
            if (tutorOrClientProfile) {
              this.$store.commit('profile/setProfile', tutorOrClientProfile[0]);
            } else {
              this.$store.commit('profile/setProfile', profilesWithoutPin[0]);
            }
          }
        }

        const isAccountData = localStorage.getItem('isAccountData');
        if (isAccountData) {
          this.$store.commit(
            'account/setIsAccountData',
            isAccountData === 'true',
          );
        }

        // const landingPage = sessionStorage.getItem('ga/landingUrl');
        // if (!landingPage) {
        const currentUrl = this.$router.currentRoute.fullPath;

        if (this.checkConsetsCookie) {
          sessionStorage.setItem('ga/landingUrl', currentUrl);
        }

        // }
      } catch {}
    }

    this.getTags();

    this.checkAccess();

    this.getWebsiteRoutes();
  },
  methods: {
    async getWebsiteRoutes() {
      const dataWebsite = await this.$helpers.getWebsiteRoutes();

      this.$store.commit('common/setWebsiteRoutes', dataWebsite);
      this.$store.commit(
        'common/setProductValuesPages',
        dataWebsite.productValuesPages,
      );
    },
    goTop() {
      const top = 0;

      window.scroll({
        top,
        left: 0,
        behavior: 'smooth',
      });
    },
    async fetchCourseData() {
      await this.$http
        .get('/accounts/teacher-day-blacklist')
        .then((response: { data: any }) => {
          this.$store.commit('common/setShowTeacherDayBaner', response.data);
        })
        .catch((error: any) => console.log('error', error));
    },
    getTags() {
      if (process.client) {
        const {
          marketing_source: marketingSource,
          placement,
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_medium: utmMedium,
          utm_content: utmContent,
          utm_id: utmId,
          promo_code: promoCode,
        } = this.$route.query;

        if (
          marketingSource ||
          placement ||
          utmSource ||
          utmCampaign ||
          utmMedium ||
          utmContent ||
          utmId ||
          promoCode
        ) {
          const tags = {
            marketingSource,
            placement,
            utmSource,
            utmCampaign,
            utmMedium,
            utmContent,
            utmId,
            promoCode,
          };

          if (this.checkConsetsCookie) {
            sessionStorage.setItem('ga/tags', JSON.stringify(tags));
          }
        }
      }
    },
    async checkAccess(): Promise<void> {
      const accountClaims = await this.$store.dispatch('account/getClaims');
      const pageName =
        this.$route.name?.replace('___en', '').replace('___pl', '') || '';
      const keepEnrollTimerPageNames = [
        'Enroll',
        'Enroll1',
        'Enroll2',
        'EnrollCompleteData',
        'PaymentPage',
        'PaymentForm',
        'EnrollPin',
        'EnrollVerifyProfileOtp',
      ];
      if (
        this.showEnrollTimer &&
        !keepEnrollTimerPageNames.includes(pageName)
      ) {
        this.$store.commit('common/setShowEnrollTimer', false);
      }
      // console.log('page name', pageName);
      // if (this.isTutor && !this.isTutorCompleteRegistration && [
      //   'Help',
      //   'Onboarding',
      // ].indexOf(pageName) === -1) {
      //   this.$router.push('/' + this.$root.$i18n.locale + '/onboarding');
      //   return;
      // }
      if (
        !!this.$store.state.account.account?.uid &&
        !this.$store.state.profile.profile?.id &&
        ![
          'Profiles',
          'ProfileAdd',
          'InsertPin',
          'ProfileSettings',
          'WelcomeScreen',
          'EditPin',
          'Enroll',
          'Enroll1',
          'Enroll2',
          'EnrollPaymentFailed',
          'EnrollPaymentSuccess',
          'PaymentForm',
          'PaymentPage',
          'EnrollWithoutAccount',
          'EnrollWithoutAccountWithService',
          'EnrollCompleteData',
          'EnrollOTP',
          'EnrollPin',
          'EnrollVerifyProfileOtp',
          'Register',
          'RegisterTeacher',
          'RegisterSuccess',
          'RegisterFail',
          'EnrollSuccess',
          'Home',
          'Offer',
          'ContinueRegister',
          'Help',
          'EnrollPhoneNumberExist',
          'SummaryResult',
          'EnrollContinues',
          'PaymentServiceGroup',
          'DashboardQuizStart',
          'Documents',
          'PrivacyPolicy',
          'Referral',
          'ReferralInvited',
          'IndividualBuy',
          'IndividualPaymentFinished',
          'IndividualPaymentFinish',
          'IndividualTrial',
          'IndividualProceed',
        ].includes(pageName) &&
        (!accountClaims.tutor ||
          (accountClaims.tutor && accountClaims.tutorRegistrationCompleted))
      ) {
        this.$router.push('/' + this.$root.$i18n.locale + '/profiles/');
      }

      if (
        this.$store?.state?.account?.account?.uid &&
        [
          'Register',
          'RegisterTeacher',
          'RegisterSuccess',
          'RegisterFail',
        ].includes(pageName)
      ) {
        if (
          pageName === 'Register' &&
          this.$route.path.includes('individual')
        ) {
          const courseType = (this.$route.query?.courseType || '').toString();
          const page = (this.$route.query?.page || '0')
            .toString()
            .replace('%2F', '')
            .replace('/', '');
          let path = `/${this.$root.$i18n.locale}/individual/${courseType}/${this.$route?.params?.courseId}`;
          if (page && page !== '0') {
            path = path.concat(`?page=${page}`);
          }
          this.$router.push(path);
        } else {
          this.$router.push('/' + this.$root.$i18n.locale + '/profiles/');
        }
      }
    },
    handleScroll() {
      let cookies: string;
      try {
        cookies = localStorage.getItem('cookie');
      } catch {}
      if (!this.tutorekWasLaunched && cookies === 'all') {
        const enviroments = {
          development: 'dev',
          'development-2': 'dev_2',
          staging: 'stg',
          performance: 'perf',
          tutore: 'prod',
          preview: 'prod',
          local: 'local',
        };
        const currentEnviroment = window.location.hostname.split(
          '.',
        )[1] as keyof typeof enviroments;
        const tutorek = document.createElement('script');
        tutorek.setAttribute(
          'src',
          `https://actionbot-proxy-tutore.12ls0c23zaf6.eu-de.codeengine.appdomain.cloud/actionbot/script/${
            window.location.hostname.startsWith('localhost')
              ? 'local'
              : enviroments[currentEnviroment] || 'dev'
          }/actionbot.js?v=${Date.now()}`,
        );
        document.head.appendChild(tutorek);
        this.tutorekWasLaunched = true;
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
  },
};
